@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Jost";
  color: #333;
}

.content {
  padding:0;
  margin:0;
  overflow-x: hidden;
  background-color: #cbe6ef;
}

.white{
  background-color: #fff;
}

/* NAVIGATION BAR */
.navbar {
  padding: 20px 20px 30px 20px;
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  border-bottom: 1px solid #fff;
  width: 100vw;
  overflow: hidden;
}
.navbar h1 {
  padding: 1vw 0vw 0vw 1vw; 
  font-size:x-large; 
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #81c3d7;
}

/* FOOTER */
.list {
  background-color:#cbe6ef;
  text-align: center;
  display: block;
  padding: 3vh
}
.divided {
  display: flex;
  align-items: center;
}
.divider {
  flex-grow: 1;
  border-bottom: 2px solid black;
  margin: 5px 50px 5px 50px
}

.footer_links li {
  list-style-type: none;
  display: inline-block;
  padding: 10px;
}
.footer_links a {
  color: #010b43;
}

/*BODY*/
body {
  background-color: #cbe6ef;
}

/* NEWSLETTER SIGN UP */
.bkgd{
  background-color: #cbe6ef;
}
.signup {
  background-color: #fff;
  padding: 3vw;
  border-radius: 25px;
  background-color: white;
  border: 2px solid #010b43;
}

.sign-up li{
  list-style-type: none;
  text-decoration: none;
  padding: 10px;
}

.d-grid button {
  margin-left: 16px;
  text-decoration: none;
  padding: 10px;
  color: white;
  background-color: #010b43;
  border: none;
  border-radius: 12px;
}

.d-grid button:hover {
  background-color: #81c3d7;
}

/* INTRODUCTION */
.homepage {
  background-color: #cbe6ef;
}

.introduction {
  padding: 20px;
  background-color: #cbe6ef;
  border-radius: 10px;
}

.sponsors {
  background-color: #cbe6ef;
  border-radius: 10px;
}

.sponsors button {
  margin-left: 16px;
  text-decoration: none;
  padding: 10px 25px;
  color: white;
  background-color: #010b43;
  border: none;
  border-radius: 12px;
  min-width: 100px;
}

.introduction {
  min-height: 60vh;
  height: auto;
  padding: 50px;
  background: #cbe6ef;
  color: #010b43;
  padding-bottom: 100px;
}
.left {
  padding-top: 5vh;
  float:left;
  width: 50%;
  margin: auto;
}
.left h1 {
  text-align: left;
}

.left h3 {
  text-align: left;
  font-weight:normal
}
.left img {
  padding-top:5vh;
  justify-content: center;

}
.right {
  float: right;
  justify-content: center;
}

/*SPONSORS*/
.sponsors {
  background-color: #cbe6ef;
  padding: 5vh;
}
.sponsors img {
  padding: 3vh;
}

/*ARTICLES*/
.blog-list{
  background-color: #cbe6ef;
  padding: 3vw;
}
.blog-preview {
  padding: 10px 16px;
  margin: 20px 0;
}
.blog-preview:hover {
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
}
.blog-preview h2 {
  font-size: 20px;
  color: #010b43;
  margin-bottom: 8px;
}

.blog-preview a {
  text-decoration: none;
}
.blog-preview a:hover {
  text-decoration:underline;
}

/*CALENDER*/
.event-calendar *,
.event-calendar div
{
  -webkit-transition: all 0.3s ease;
-moz-transition: all 0.3s ease;
-ms-transition: all 0.3s ease;
-o-transition: all 0.3s ease;
transition: all 0.3s ease;
}


.event-calendar {
  border:3px solid #010b43;
  width:400px;
  margin: 20px;
  min-width:300px;
  background:#fff;
  border-radius: 25px;

}

.event-calendar a {
  text-decoration:none;
  color:#010b43;
  

}

.event-calendar a:hover {
  opacity:.7;
  color:#cbe6ef;
}

.event-calendar a:hover .title-event {
  transform:translate(5%,0);
  -webkit-transform:translate(5%,0);
  -moz-transform:translate(5%,0);
  
}



.event-calendar ul,
.event-calendar ul li,
.event-calendar h1,
.event-calendar h2
{
  margin:0;
  padding:0;
  list-style:none;
}

.event-calendar .events-body ul li {
  position:relative;
   border-bottom:1px solid #81c3d7;
}
.event-calendar .events-body ul li:last-child {
  border:0;
}

.event-calendar .heading-title {
  padding:10px 20px;
  border-bottom:4px solid ;
  font-size:.9em;
  position:relative;
  font-family: 'Roboto', sans-serif;
}

.event-calendar .icon-box {
  position:absolute;
  right:20px;
  top:0;
  bottom:0;
  margin:auto;
  height:30px;
  font-size:1.8em;
}

/* EVENTS LEFT CONTES */
.event-calendar .event-left-content {
  padding:15px 20px;
}

.event-calendar .event-left-content .title-event {
  width:80%;
}

.event-calendar .event-left-content .title-event h2 {
  margin:0;
  padding:0;
}

.event-calendar .event-left-content .event-date {
  font-size:.8em;
}

.event-calendar .event-left-content .event-location {
  font-weight:bold;
  font-size:.8em;
}


/* EVENT ICON HOLDER  */
.event-calendar .event-icon{
  position:absolute;
  width:auto;
  height:50%;
  min-height: 60px;
  display:block;
  background:#010b43;
  right:20px;
  top:0;
  bottom:0;
  padding: 5vh;
  margin:auto;
  text-align:center;
  
  -webkit-border-radius: 8px;
  border-radius: 8px;
  
  padding:2px 10px 2px 10px;
    
}

.event-calendar .event-icon .month,
.event-calendar .event-icon .date {
  display:block;
  color: #fff;
}

.event-calendar .event-icon .month {
  text-transform:uppercase;
  font-weight:bold;
  font-size:1.1em;
  margin-top:5px
}

.event-calendar .event-icon .date {
  font-size:1.1em;
  font-weight:bold;
  
}


/* FOR RESPONSIVE ONLY  */
@media only screen and (max-width:480px) {
  .event-calendar {
    width:100%;
    margin:0;
  }
  .event-calendar .heading-title h1 {
    font-size:1em;
  }
  
  .event-calendar .title-event h2 {
    font-size:.9em;
  }
  
  .event-calendar .event-left-content .event-date,
  .event-calendar .event-left-content .event-location {
    font-size:.8em;
  }
  
  
}

/* SLIDESHOW */
@keyframes slide {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

.slideshow-container {
  position: relative;
  width: 100%;
  height: 300px;
}

.slideshow {
  display: flex;
  width: 100%;
  animation: slide 30s linear infinite;
}

.slide {
  width: 500px;
  height: 300px;
}

/*CONTACT*/
.contact-links a{
  text-decoration: none;
  list-style-type: none;
  text-align: center;
  margin: 1vh;
  color: #010b43;
  font-size: large;
  height: 100%;

}
.contact-links a:hover{
  color: #81c3d7;
}

.contact{
  padding: 10vh;
  background-color: #cbe6ef;
}

/*ARTICLES*/
article {
  padding: 5vh 0vh 5vh 0vh;
  
}

/*SPONSOR US*/

.spnsr-button {
  margin: 70px;
  text-decoration: none;
  padding: 10px;
  color: white;
  background-color: #010b43;
  border: none;
  border-radius: 12px;
}

.spnsr-button:hover {
  background-color: #61dafb;
}

.spns-package {
  padding-bottom: 30px;
}

/* TESTIMONIALS */
.horizontal-list {
  display: flex;
  justify-content: center;
  list-style: none;
}

.horizontal-list-item{
  margin-right: 30px;
}

.horizontal-list-text{
  font-weight:400;
}

.testimonials{
  border-radius: 25px;
  border: 2px solid #010b43;
}

.stat-box{
  width: 90%;
  border: #fff;
  border-radius: 16px;
  padding: 4%;
  margin: 3%;
  box-shadow: #010b43;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sponsor-us .section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;
  background-color: #cbe6ef; 
  border-radius: 10px;
  margin-bottom: 40px; 
  height: auto;
}

.text-left, .text-right {
  flex: 1;
  padding: 20px;
}

.image-right, .image-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-left {
  order: -1; 
}

.text-right {
  order: 1;
  padding-right: 30px; 
  position: relative;
  right: 2%;
}

/* image styles */
.image-mpl {
  max-width: 20%;
  max-height: 30%;
}

.image-aops {
  max-width: 10%;
  max-height: 10%;
  min-height: 10%;
}

.image-ontario-fluid {
  width: 30%;
  height: auto;
}
/* couldnt find the css for this so i flagged as important */
.image-workshop {
  max-width: 30% !important;
  height: 30%;
  position: relative;
  right: 5%;
  
}

/* new image*/
.image-workshop2 {
  max-width: 30% !important;
  height: 30%;
  position: relative;
  left: 5%;
  
}

/* only applies for desktop and doesnt distort if on mobile*/
@media (min-width: 768px) {
.sponsorus-text {
  position: relative;
  left: 5%;
}
}


